import React, { useState, useEffect, useRef } from "react";
import {
  GelForm,
  GelFormField,
  GelTextInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelContainerLite,
  GelNumberInput,
  GelModal,
  GelParagraph,
  GelLabel,
  GelSpinner,
  GelCaption,
  GelSelect,
  GelStandardDateInput,
  GelColumnLayout,
  GelIcon,
  GelAutoCompleteInput,
} from "@tal-gel/components";
import { useLocation, useNavigate } from "react-router-dom";
import { getGelTokens } from "@tal-gel/theming";
import axios, { AxiosResponse } from "axios";
import { useCookies } from "react-cookie";
import Header from "../../common/header";
import Footer from "../../common/footer";
import TfnInstructions from "./tfnInstructions";
import "./tfnInstructions.css";
import { API } from "../../constants/constants";
import {
  SUBMIT_TFN_FORM_QUERY,
  ADDRESS_SEARCH,
} from "../../graphql/graphql-bff";
import { GET_TFNPAGE_CONTENT_QUERY } from "../../graphql/graphql-contentful";
import TooltipModal from "../../components/TooltipModal";
import {
  TfnNumberTooltip,
  PaidTooltip,
  LoanTooltip,
  ResidentTooltip,
  TaxFreeTooltip,
} from "../../components/TooltipTemplates";

const TfnForm = () => {
  const [contentfulData, setContenfulData] = useState<null | {
    mmcTfnPageCollection: any;
  }>(null);

  const claimId = window.sessionStorage.getItem("claimId") ?? "";
  const requirementId = window.sessionStorage.getItem("requirementId") ?? "";

  const { state } = useLocation();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [ssoCookies] = useCookies(["aid", "rid", "ssoid", "tpid", "sid"]);
  const [tfnAltRequired, setTfnAltRequired] = useState(false);
  const [isTfnInvalid, setIsTfnInvalid] = useState(false);
  const [tfnErrorMsg, setTfnErrorMsg] = useState("");
  const [addressInputManual, setAddressInputManual] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const tfnFieldRef = useRef<HTMLElement>(null);

  //tooltip controls
  const [TfnTooltipOpen, setTfnTooltipOpen] = useState(false);
  const [PaidTooltipOpen, setPaidTooltipOpen] = useState(false);
  const [LoanTooltipOpen, setLoanTooltipOpen] = useState(false);
  const [ResidentTooltipOpen, setResidentTooltipOpen] = useState(false);
  const [TaxFreeTooltipOpen, setTaxFreeTooltipOpen] = useState(false);

  const stateToNumber: { [key: string]: string } = {
    ACT: "0",
    NSW: "1",
    NT: "2",
    QLD: "3",
    SA: "4",
    TAS: "5",
    VIC: "6",
    WA: "7",
  };

  const { formData, onFormDataChange, setFormData } = useGelFormData({
    tfnNumber: "",
    tfnNumberAlt: "",
    title: 0,
    otherTitle: "",
    surname: "",
    firstName: "",
    otherName: "",
    streetAddress: "",
    suburb: "",
    state: 0,
    postcode: "",
    previousSurname: "",
    previousFirstName: "",
    previousOtherName: "",
    email: "",
    dateOfBirth: "",
    basisOfPayment: 0,
    residencyStatus: 0,
    taxFreeStatus: 0,
    educationLoan: 0,
    fullName: "",
    requirementId: 0,
    claimId: 0,
  });

  const origin = sessionStorage.getItem("origin") ?? "";

  useEffect(() => {
    if (!state) {
      sessionStorage.removeItem("origin");
      console.log(atob(origin));

      if (atob(origin).indexOf("?source=") > 0) {
        window.location.replace(atob(origin));
      } else {
        sessionStorage.removeItem("origin");
        window.location.replace(atob(origin) + "?source=TFN");
      }
    }

    console.log("Page loads up");
    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_TFNPAGE_CONTENT_QUERY,
      })
      .then(async (response) => {
        setContenfulData(response.data?.data);
      });
  }, []);

  const onChangeTfnAlt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.value !== "") {
      setTfnAltRequired(false);
    }
  };
  const onChangeTfn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const tfn = e.target.value;
    if (e.target.value === "") {
      setIsTfnInvalid(false);
      setTfnErrorMsg("");
    } else {
      setTfnAltRequired(false);
      if (tfn.length < 9) {
        //tfn needs to be at least 9 digits
        setIsTfnInvalid(true);
        setTfnErrorMsg(
          contentfulData?.mmcTfnPageCollection.items[0]?.errorMessageContent
            .tfnMinLength
        );
      } else {
        var digits = tfn.split("").map((x: string) => parseInt(x));

        var sum =
          digits[0] * 1 +
          digits[1] * 4 +
          digits[2] * 3 +
          digits[3] * 7 +
          digits[4] * 5 +
          digits[5] * 8 +
          digits[6] * 6 +
          digits[7] * 9 +
          digits[8] * 10;
        var remainder = sum % 11;

        if (remainder === 0) {
          //valid tfn
          setIsTfnInvalid(false);
        } else {
          //invalid tfn
          setIsTfnInvalid(true);
          setTfnErrorMsg(
            contentfulData?.mmcTfnPageCollection.items[0]?.errorMessageContent
              .tfnInvalid
          );
        }
      }
    }
  };

  const onAddressFieldChange = (e: String) => {
    //setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.length >= 3) {
      const variables = {
        searchParam: e,
      };

      axios
        .post(
          API.BASEURL,
          {
            query: ADDRESS_SEARCH,
            variables: variables,
          },
          {
            headers: {
              "x-aid": ssoCookies.aid,
              "x-rid": ssoCookies.rid,
              ssoid: ssoCookies.ssoid,
              tpid: ssoCookies.tpid,
              sid: ssoCookies.sid,
            },
          }
        )
        .then((response: AxiosResponse<any>) => {
          console.log(response.data.data.memberAddress.searchAddressModel);
          const addressArray =
            response.data.data.memberAddress.searchAddressModel;
          setAddressData(addressArray.map((x: any) => x.addressLine));
        })
        .catch((error: any) => {
          setAddressInputManual(true);
        });
    }
  };

  const onOptionClick = (address: string) => {
    console.log(address);
    if (address === "Address not listed?") {
      setAddressInputManual(true);
      setFormData({ ...formData, ["streetAddress"]: address });
    }
  };

  const parseAddress = (address: string) => {
    const regex = /(.+),\s*(.+)\s+(\b[A-Z]{2,3}\b)\s+(\d{4})$/;
    const match = address.match(regex) ?? [];
    console.log(match);

    if (match) {
      const suburb = match[2].trim();
      const stateAbbreviation = match[3].trim();
      console.log("state: ", stateAbbreviation);
      const postcode = match[4].trim();

      const state =
        stateToNumber[stateAbbreviation] !== undefined
          ? stateToNumber[stateAbbreviation]
          : 0;

      return { suburb: suburb, state: state, postcode: postcode };
    }
  };

  const onClickCancel = () => {
    setOpenModal(true);
    setIsWaiting(false);
  };

  const onClickCancelConfirm = () => {
    const origin = sessionStorage.getItem("origin") ?? "";

    if (atob(origin).indexOf("?source=") > 0) {
      window.location.replace(atob(origin));
    } else {
      sessionStorage.removeItem("origin");
      window.location.replace(atob(origin) + "?source=TFN");
    }
  };

  const onSubmit = () => {
    //scroll up to tfn ques if invalid
    if (formData.tfnNumberAlt === "" && formData.tfnNumber === "") {
      setTfnAltRequired(true);
      tfnFieldRef.current?.scrollIntoView();
    } else {
      setTfnAltRequired(false);
    }

    //show spinner and text
    setIsWaiting(true);

    //api call
    console.log(formData.streetAddress);
    const variables = {
      tfnSubmitModel: {
        tfnNumber: formData.tfnNumber,
        tfnNumberAlt: parseInt(formData.tfnNumberAlt),
        title: parseInt(formData.title),
        otherTitle: formData.otherTitle,
        surname: formData.surname,
        firstName: formData.firstName,
        otherName: formData.otherName,
        streetAddress: formData.streetAddress,
        suburb: addressInputManual
          ? formData.suburb
          : parseAddress(formData.streetAddress)?.suburb,
        state: parseInt(
          addressInputManual
            ? formData.state
            : parseAddress(formData.streetAddress)?.state
        ),
        postcode: addressInputManual
          ? formData.postcode
          : parseAddress(formData.streetAddress)?.postcode,
        previousSurname: formData.previousSurname,
        previousFirstName: formData.previousFirstName,
        previousOtherName: formData.previousOtherName,
        email: formData.email,
        dateOfBirth: formData.dateOfBirth,
        basisOfPayment: parseInt(formData.basisOfPayment),
        residencyStatus: parseInt(formData.residencyStatus),
        taxFreeStatus: parseInt(formData.taxFreeStatus),
        educationLoan: parseInt(formData.educationLoan),
        fullName:
          formData.firstName +
          " " +
          formData.otherName +
          " " +
          formData.surname,
        requirementId: parseInt(requirementId),
        claimId: parseInt(claimId),
      },
    };

    console.log(variables);
    axios
      .post(
        API.BASEURL,
        {
          query: SUBMIT_TFN_FORM_QUERY,
          variables: variables,
        },
        {
          headers: {
            "x-aid": ssoCookies.aid,
            "x-rid": ssoCookies.rid,
            ssoid: ssoCookies.ssoid,
            tpid: ssoCookies.tpid,
            sid: ssoCookies.sid,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        navigate("/success", {
          state: {
            auth: true,
          },
        });
      })
      .catch((error: any) => {
        navigate("/error");
      });
  };
  return (
    <div
      style={{
        backgroundColor: getGelTokens().global.themeColorGrayT05,
      }}
    >
      <Header
        title={contentfulData?.mmcTfnPageCollection.items[0]?.tfnPageTitle}
      />
      <GelContainerLite
        gutter="small"
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <GelBoxLayout
          gap="medium"
          alignment="start"
          space="auto"
          distribution="spaceBetween"
          vertical="true"
          padding="large"
          margin="large"
          style={{
            marginTop: -getGelTokens().global.sizeBaseUnit * 6,
            backgroundColor: getGelTokens().global.themeColorWhite,
            border: "1px solid",
            borderColor: getGelTokens().global.themeColorGrayT20,
          }}
        >
          <TfnInstructions></TfnInstructions>
          <GelForm
            labelAtTop
            preventSubmitOnInvalid
            disableOnSubmit
            onSubmit={onSubmit}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: getGelTokens().global.sizeBaseX2,
              }}
            >
              <GelLabel marginRight="small">
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.taxFileNumberQuestion
                }
              </GelLabel>
              <GelIcon
                name="AlertCircle"
                onClick={() => {
                  setTfnTooltipOpen(true);
                }}
                width={getGelTokens().global.sizeBaseX2 * 2.5}
                height={getGelTokens().global.sizeBaseX2 * 2.5}
                color={getGelTokens().global.themeColorBackgroundSuccess}
              />
            </div>
            <GelFormField ref={tfnFieldRef}>
              <GelNumberInput
                small
                name="tfnNumber"
                value={formData.tfnNumber}
                onChange={onChangeTfn}
                error={isTfnInvalid || tfnAltRequired}
                noFormat
              />
              {isTfnInvalid && (
                <GelBoxLayout marginTop="small">
                  <GelCaption
                    condensed
                    style={{
                      color: getGelTokens().global.themeColorDanger,
                    }}
                  >
                    {tfnErrorMsg}
                  </GelCaption>
                </GelBoxLayout>
              )}
            </GelFormField>

            <GelFormField label="OR">
              <GelSelect
                name="tfnNumberAlt"
                value={formData.tfnNumberAlt}
                onChange={onChangeTfnAlt}
                placeholder="Please select"
                error={tfnAltRequired}
                options={[
                  {
                    label:
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.tfnExemptionOptions
                        .separateApplication,
                    value: "1",
                  },
                  {
                    label:
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.tfnExemptionOptions.underage,
                    value: "2",
                  },
                  {
                    label:
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.tfnExemptionOptions.pension,
                    value: "3",
                  },
                ]}
              />
              {tfnAltRequired && (
                <GelBoxLayout marginTop="small">
                  <GelCaption
                    condensed
                    style={{
                      color: getGelTokens().global.themeColorDanger,
                    }}
                  >
                    {
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.errorMessageContent.tfnAltReq
                    }
                  </GelCaption>
                </GelBoxLayout>
              )}
            </GelFormField>
            <GelColumnLayout
              marginBottom="small"
              verticalGutter={getGelTokens().global.sizeNone}
            >
              <GelLabel>What is your name?</GelLabel>
              <GelFormField label="Title">
                <GelSelect
                  name="title"
                  value={formData.title}
                  onChange={onFormDataChange}
                  placeholder="Please select"
                  options={[
                    { label: "Mr", value: "1" },
                    { label: "Ms", value: "2" },
                    { label: "Mrs", value: "3" },
                    { label: "Others", value: "4" },
                  ]}
                  required
                  requiredErrorMsg={
                    contentfulData?.mmcTfnPageCollection.items[0]
                      ?.errorMessageContent.required
                  }
                />
              </GelFormField>
            </GelColumnLayout>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .surnameFieldText
              }
            >
              <GelTextInput
                name="surname"
                value={formData.surname}
                onChange={onFormDataChange}
                required
                requiredErrorMsg={
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.errorMessageContent.required
                }
              />
            </GelFormField>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .firstnameFieldText
              }
            >
              <GelTextInput
                name="firstName"
                value={formData.firstName}
                onChange={onFormDataChange}
                required
                requiredErrorMsg="This field is required"
              />
            </GelFormField>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .othernameFieldText
              }
            >
              <GelTextInput
                name="otherName"
                value={formData.otherName}
                onChange={onFormDataChange}
                noFormat
              />
            </GelFormField>
            <GelColumnLayout>
              <GelLabel marginRight="small">
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.addressSectionHeading
                }
              </GelLabel>
              {!addressInputManual ? (
                <GelFormField
                  label={
                    contentfulData?.mmcTfnPageCollection.items[0]
                      ?.tfnQuestionnaire.addressFieldText.address
                  }
                >
                  <GelAutoCompleteInput
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={onFormDataChange}
                    data={addressData}
                    onSearch={onAddressFieldChange}
                    onOptionClick={onOptionClick}
                    maxHeight={`${getGelTokens().global.sizeBase * 50}px`}
                    required
                    errorMsg={{
                      required: "This field is required",
                    }}
                  />
                </GelFormField>
              ) : (
                <div>
                  <GelFormField
                    label={
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.addressFieldText.address
                    }
                  >
                    <GelTextInput
                      name="streetAddress"
                      value={formData.streetAddress}
                      onChange={onFormDataChange}
                      noFormat
                    />
                  </GelFormField>

                  <GelFormField
                    label={
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.addressFieldText.suburb
                    }
                  >
                    <GelTextInput
                      name="suburb"
                      value={formData.suburb}
                      onChange={onFormDataChange}
                      noFormat
                    />
                  </GelFormField>
                  <GelFormField
                    label={
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.addressFieldText.state
                    }
                  >
                    <GelSelect
                      name="state"
                      value={formData.state}
                      onChange={onFormDataChange}
                      placeholder="Please select"
                      options={[
                        { label: "ACT", value: "1" },
                        { label: "NSW", value: "2" },
                        { label: "NT", value: "3" },
                        { label: "QLD", value: "4" },
                        { label: "SA", value: "5" },
                        { label: "TAS", value: "6" },
                        { label: "VIC", value: "7" },
                        { label: "WA", value: "8" },
                      ]}
                      required
                      errorMsg={{
                        required: "This field is require",
                      }}
                    />
                  </GelFormField>
                  <GelFormField
                    label={
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.addressFieldText.postcode
                    }
                  >
                    <GelNumberInput
                      name="postcode"
                      value={formData.postcode}
                      onChange={onFormDataChange}
                      noFormat
                    />
                  </GelFormField>
                </div>
              )}
            </GelColumnLayout>
            <GelColumnLayout marginTop="large" marginBottom="small">
              <GelLabel marginRight="small">
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.previousNameSectionHeading
                }
              </GelLabel>
              <GelFormField
                label={
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.surnameFieldText
                }
              >
                <GelTextInput
                  name="previousSurname"
                  value={formData.previousSurname}
                  onChange={onFormDataChange}
                  noFormat
                />
              </GelFormField>
            </GelColumnLayout>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .firstnameFieldText
              }
            >
              <GelTextInput
                name="previousFirstName"
                value={formData.previousFirstName}
                onChange={onFormDataChange}
                noFormat
              />
            </GelFormField>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .othernameFieldText
              }
            >
              <GelTextInput
                name="previousOtherName"
                value={formData.previousOtherName}
                onChange={onFormDataChange}
                noFormat
              />
            </GelFormField>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .primaryEmailAddressFieldText
              }
            >
              <GelTextInput
                name="email"
                value={formData.email}
                onChange={onFormDataChange}
                noFormat
              />
            </GelFormField>
            <GelFormField
              label={
                contentfulData?.mmcTfnPageCollection.items[0]?.tfnQuestionnaire
                  .dobFieldText
              }
            >
              <GelStandardDateInput
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={onFormDataChange}
                required
                lt="9999-12-31"
                errorMsg={{
                  required: `this is required`,
                  compare: `compare msg`,
                  format: `format error`,
                }}
              />
            </GelFormField>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: getGelTokens().global.sizeBaseX2,
              }}
            >
              <GelLabel marginRight="small">
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.paymentBasisFieldText
                }
              </GelLabel>
              <GelIcon
                name="AlertCircle"
                onClick={() => {
                  setPaidTooltipOpen(true);
                }}
                width={getGelTokens().global.sizeBaseX2 * 2.5}
                height={getGelTokens().global.sizeBaseX2 * 2.5}
                color={getGelTokens().global.themeColorBackgroundSuccess}
              />
            </div>
            <GelFormField>
              <GelSelect
                name="basisOfPayment"
                value={formData.basisOfPayment}
                onChange={onFormDataChange}
                placeholder="Please select"
                options={[
                  { label: "Full time employment", value: "1" },
                  { label: "Part time employment", value: "2" },
                  { label: "Labour Hire", value: "3" },
                  {
                    label: "Superannuation or annuity income stream",
                    value: "3",
                  },
                  { label: "Casual employment", value: "4" },
                ]}
                required
                errorMsg={{
                  required: "This question is mandatory",
                }}
              />
            </GelFormField>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: getGelTokens().global.sizeBaseX2,
              }}
            >
              <GelLabel marginRight="small">Are you:</GelLabel>
              <GelIcon
                name="AlertCircle"
                onClick={() => {
                  setResidentTooltipOpen(true);
                }}
                width={getGelTokens().global.sizeBaseX2 * 2.5}
                height={getGelTokens().global.sizeBaseX2 * 2.5}
                color={getGelTokens().global.themeColorBackgroundSuccess}
              />
            </div>
            <GelFormField>
              <GelSelect
                name="residencyStatus"
                defaultValue={formData.residencyStatus}
                onChange={onFormDataChange}
                placeholder="Please select"
                options={[
                  {
                    label:
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.taxPurposeOptions.ausResident,
                    value: "1",
                  },
                  {
                    label:
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.taxPurposeOptions.workingHoliday,
                    value: "2",
                  },
                  {
                    label:
                      contentfulData?.mmcTfnPageCollection.items[0]
                        ?.tfnQuestionnaire.taxPurposeOptions.foreignResident,
                    value: "3",
                  },
                ]}
                required
                errorMsg={{
                  required: "This question is mandatory",
                }}
              />
            </GelFormField>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: getGelTokens().global.sizeBaseX2,
              }}
            >
              <GelLabel marginRight="small">
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.taxThresholdQuestionText
                }
              </GelLabel>
              <GelIcon
                name="AlertCircle"
                onClick={() => {
                  setTaxFreeTooltipOpen(true);
                }}
                width={getGelTokens().global.sizeBaseX2 * 2.5}
                height={getGelTokens().global.sizeBaseX2 * 2.5}
                color={getGelTokens().global.themeColorBackgroundSuccess}
              />
            </div>
            <GelParagraph>
              <div style={{ marginTop: getGelTokens().global.sizeBaseUnit }}>
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.taxThresholdInfoText1
                }
              </div>
              <div style={{ marginTop: getGelTokens().global.sizeBaseUnit }}>
                Answer <b>no</b> here if you are a foreign resident or working
                holiday maker, except if you are a foreign resident in receipt
                of an Australian Government pension or allowance.
              </div>
            </GelParagraph>
            <GelFormField>
              <GelSelect
                name="taxFreeStatus"
                value={formData.taxFreeStatus}
                onChange={onFormDataChange}
                placeholder="Please select"
                options={[
                  { label: "No", value: "0" },
                  { label: "Yes", value: "1" },
                ]}
                required
                requiredErrorMsg={
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.errorMessageContent.required
                }
              />
            </GelFormField>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: getGelTokens().global.sizeBaseX2,
              }}
            >
              <GelLabel marginRight="small">
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.debtQuestionText
                }
              </GelLabel>
              <GelIcon
                name="AlertCircle"
                onClick={() => {
                  setTfnTooltipOpen(true);
                }}
                width={getGelTokens().global.sizeBaseX2 * 2.5}
                height={getGelTokens().global.sizeBaseX2 * 2.5}
                color={getGelTokens().global.themeColorBackgroundSuccess}
              />
            </div>
            <GelParagraph>
              <div style={{ marginTop: getGelTokens().global.sizeBaseUnit }}>
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.tfnQuestionnaire.debtQuestionInfoText
                }
              </div>
            </GelParagraph>
            <GelFormField>
              <GelSelect
                name="educationLoan"
                value={formData.educationLoan}
                onChange={onFormDataChange}
                placeholder="Please select"
                options={[
                  { label: "No", value: "0" },
                  { label: "Yes", value: "1" },
                ]}
                required
                errorMsg={{
                  required: "This question is mandatory",
                }}
              />
            </GelFormField>
            <GelColumnLayout
              verticalGutter={getGelTokens().global.sizeBaseUnit * 6}
              marginBottom="medium"
            >
              <div className="line"></div>

              <GelFormField label="Declaration by payee(type full legal name)">
                <GelTextInput
                  name="declaration"
                  required
                  requiredErrorMsg={
                    contentfulData?.mmcTfnPageCollection.items[0]
                      ?.errorMessageContent.required
                  }
                ></GelTextInput>
              </GelFormField>
            </GelColumnLayout>

            <GelBoxLayout
              alignment="start"
              width={getGelTokens().global.sizeBaseUnit * 60}
            >
              <GelFormField>
                <GelButton
                  name="" // Makes it a managed component
                  primary
                  medium
                  submit
                >
                  Submit
                </GelButton>
              </GelFormField>
              <GelFormField>
                <GelButton
                  name="" // Makes it a managed component
                  secondary
                  medium
                  onClick={onClickCancel}
                >
                  Cancel
                </GelButton>
              </GelFormField>
            </GelBoxLayout>
            <GelBoxLayout marginTop="small">
              {isWaiting && (
                <div style={{ display: "flex" }}>
                  <GelSpinner small left />
                  <GelParagraph paddingLeft="large">
                    Please wait while the form is being submitted
                  </GelParagraph>
                </div>
              )}
            </GelBoxLayout>
          </GelForm>
        </GelBoxLayout>

        <GelModal
          width="sm"
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Cancel"
          sticky
          action={
            <GelBoxLayout gap="small" space="auto">
              <GelButton
                secondary
                medium
                width={getGelTokens().global.sizeBaseUnit * 22}
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </GelButton>

              <GelButton
                primary
                medium
                width={getGelTokens().global.sizeBaseUnit * 22}
                onClick={onClickCancelConfirm}
              >
                Confirm
              </GelButton>
            </GelBoxLayout>
          }
        >
          <GelParagraph>
            The details you have entered on this form will not be submitted.
          </GelParagraph>
          <GelParagraph> Are you sure you want to cancel?</GelParagraph>
        </GelModal>
        <TooltipModal
          openModal={TfnTooltipOpen}
          setOpenModal={setTfnTooltipOpen}
          content={TfnNumberTooltip}
        />
        <TooltipModal
          openModal={PaidTooltipOpen}
          setOpenModal={setPaidTooltipOpen}
          content={PaidTooltip}
        />
        <TooltipModal
          openModal={LoanTooltipOpen}
          setOpenModal={setLoanTooltipOpen}
          content={LoanTooltip}
        />
        <TooltipModal
          openModal={ResidentTooltipOpen}
          setOpenModal={setResidentTooltipOpen}
          content={ResidentTooltip}
        />
        <TooltipModal
          openModal={TaxFreeTooltipOpen}
          setOpenModal={setTaxFreeTooltipOpen}
          content={TaxFreeTooltip}
        />
      </GelContainerLite>
      <Footer />
    </div>
  );
};

export default TfnForm;
